@import "designSystem/colors.scss";

.modal-overlay {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  &.with-background {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.sicomoro-modal {
  position: absolute;
  background: rgb(255, 255, 255);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -40%;
  transform: translate(-50%, -50%);
  padding: 0px;
  overflow: auto;
  border-radius: 11px;
  outline: none;
  max-height: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .input-label {
    font-weight: 600;
    letter-spacing: 0px;
    opacity: 0.7;
    font-size: 13px;
    line-height: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.54);
  }

  .delete-modal__wrapper {
    height: 100%;
    width: 430px;
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 25px 25px 0 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        color: $error;
        font-size: 20px;
      }

      h3 {
        margin: 20px 0;
        color: #454449;
        font-weight: 700;
        font-size: 18px;
      }

      p {
        margin: 0;
        color: #acacac;
        font-weight: 500;
        text-align: center;
        font-size: 16px;
        max-width: 260px;
      }
    }

    .modal-footer {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        min-width: 115px;
      }
    }
  }

  .payment-info-modal__wrapper {
    height: 100%;
    width: 750px;
    display: flex;
    flex-direction: column;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 25px;
      font-weight: 600;
      font-size: 18px;
      color: #454449;
    }

    .modal-body {
      padding: 0px 20px 0px 20px;
      //display: grid;
      //grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      //grid-gap: 30px;

      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #000;
      }

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }

      .change-payment-status {
        width: 100%;
        padding-bottom: 0px;
      }
    }

    .modal-footer {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        min-width: 100px;
      }
    }
  }

  .manage-course-modal__wrapper {
    height: 100%;
    width: 750px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 25px;
      font-weight: 600;
      font-size: 18px;
      color: #454449;
    }

    .modal-body {
      padding: 25px 25px 0 25px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      overflow-y: auto;
      flex: 1;

      .picture-wrapper {
        display: flex;
        flex-direction: column;

        .picture {
          width: 100%;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed gray;
          margin-top: 10px;
          cursor: pointer;

          &.disabled {
            background-color: lightgray;
          }

          svg {
            color: gray;
            font-size: 24px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .input-wrapper {
        grid-column: span 3;
        margin-bottom: 20px;
      }
      .fill-row {
        grid-column: span 6;
      }

      .fill-2 {
        grid-column: span 2;
      }

      .fill-3 {
        grid-column: span 3;
      }
    }

    .modal-footer {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        min-width: 100px;
      }
    }
  }

  .manage-user-modal__wrapper {
    height: 100%;
    width: 750px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 25px;
      font-weight: 600;
      font-size: 18px;
      color: #454449;
    }

    .modal-body {
      padding: 25px 25px 0 25px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      row-gap: 0;
      overflow-y: auto;
      flex: 1;

      .modal-circular-image {
        width: 175px;
        height: 175px;
        border-radius: 50%;
        overflow: hidden;
        /* commented for demo
                float: left;
                margin-left: 125px;
                margin-top: 20px;
                */

        /*for demo*/
        display: inline-block;
        vertical-align: middle;

        .modal-avatar {
          width: 100%;
        }
      }

      .modal-separator {
        margin-bottom: 20px;
      }

      .picture-wrapper {
        display: flex;
        flex-direction: column;

        .picture {
          width: 100%;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed gray;
          margin-top: 10px;
          cursor: pointer;

          &.disabled {
            background-color: lightgray;
          }

          svg {
            color: gray;
            font-size: 24px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .input-wrapper {
        grid-column: span 3;
        margin-bottom: 20px;
      }
      .fill-row {
        grid-column: span 6;
      }

      .fill-1 {
        grid-column: span 1;
      }

      .fill-2 {
        grid-column: span 2;
      }

      .fill-3 {
        grid-column: span 3;
      }

      .fill-4 {
        grid-column: span 4;
      }

      .fill-5 {
        grid-column: span 5;
      }
    }

    .modal-error {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .modal-message {
        font-size: 14px;
        color: red;
      }
    }

    .modal-footer {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        min-width: 100px;
      }
    }
  }
}

.ql-editor {
  min-height: 130px;
}

.input-error-formik {
  .ql-toolbar.ql-snow,
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid red !important;
  }
}
