@import "designSystem/colors.scss";

.chats-wrapper {
  margin: 30px;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 140px);
  position: fixed;
  width: calc(100vw - 310px);
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  .chats-contacts {
    flex: 30%;
    border-right: 0.55px solid #c4c4c486;
    padding: 30px;
    .section-title {
      padding-bottom: 10px;
    }
    .chat-card {
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 120px;
      padding: 20px;
      background: white;
      border: 0.55px solid #c4c4c486;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 30px;
      &.notification {
        background: rgba(188, 210, 81, 0.1);
        border: 0.55px solid #000;
      }
      .text-container {
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .message-timestamp {
          text-align: right;
          width: 80px;
          font-weight: 500;
          font-size: 13px;
          color: #737581;
        }
      }
      .message-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: #737581;
        .message-content {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .message-date {
          margin-left: 5px;
        }
        .message-date::before {
          content: "• ";
        }
      }
      .notification-container {
        position: absolute;
        right: 10px;
        top: 10px;
        .notification {
          background: #a23436;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          font-size: 11px;
          color: white;
        }
      }
      .photo-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        min-width: 0;
        .photo {
          min-width: 70px;
          width: 70px;
          height: 70px;
          border-radius: 35px;
          overflow: hidden;
          align-items: center;
          justify-content: center;
        }
        .text {
          margin-left: 10px;
          padding: 10px;
          display: flex;
          height: 100%;
          flex-direction: column;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          .name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: bold;
          }
          .message {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #737581;
          }
        }
      }
    }
  }
  .chats {
    flex: 70%;
    display: flex;
    flex-direction: column;
    .name-container {
      flex: none;
      order: 1;
      display: flex;
      flex-direction: row;
      background: #ffffff;
      border-bottom: 0.55px solid #c4c4c486;
      align-items: center;
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 20px;

      .photo {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
      }
      .name {
        font-weight: 600;
        font-size: 19px;
        margin-left: 20px;
      }
    }
    .message {
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-top: 25px;
      padding-bottom: 25px;
      height: 50px;
      &.isAdmin {
        display: flex;
        justify-content: flex-end;
        padding-right: 40px;
      }
      .photo {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        &.isAdmin {
          margin-left: 20px;
        }
      }
      .text-container {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .message-text {
          background: #ffffff;
          border: 0.75px solid rgba(115, 117, 129, 0.15);
          box-sizing: border-box;
          padding: 15px;
          border-radius: 10px;
          &.isAdmin {
            border: none;
            color: #ffffff;
            background: #a1c859;
          }
        }
        .message-timestamp {
          margin-top: 10px;
          font-weight: 600;
          font-size: 11px;
          color: #c4c4c4;
          &.isAdmin {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
    .message-container {
      overflow-y: auto;
      flex: 1 1;
      flex-basis: 0;
      order: 2;
    }
    .message-input {
      background: #ffffff;
      flex: none;
      order: 3;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      margin-top: auto;
      border-top: 0.55px solid #c4c4c486;

      .input {
        width: 100%;
        border: none;
        font-size: 15px;
        margin-right: 20px;
      }
      .icon {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
        width: 60px;
        margin-right: 20px;
      }
    }
  }
}
