@import "designSystem/colors.scss";

.dashboard-layout {
  display: flex;

  .sidebar {
    width: 250px;
    background-color: $background;
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
      height: 80px;
      margin-bottom: 20px;
      background-color: $light-background;

      img {
        width: 150px;
      }
    }

    .sidebar-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 45px;
      margin-bottom: 20px;
      color: $font-primary;
      text-decoration: none;
      font-weight: 500;
      transition: background-color 0.1s, color 0.1s;
      width: 220px;
      margin: 0 auto 20px auto;
      box-sizing: border-box;
      border-radius: 10px;
      svg {
        path {
          stroke: $font-primary;
        }
      }

      .sidebar-item-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: inherit;
        text-decoration: inherit;
        padding: 0 15px;
        height: 100%;
      }

      .sidebar-icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        font-size: 20px;
      }

      &.active,
      &:hover {
        background-color: $primary;
        color: $font-light;
        cursor: pointer;
        transition: background-color 0.1s, color 0.1s;

        svg {
          path {
            stroke: #fff;
          }
        }
      }

      .sidebar-external-link {
        margin-left: auto;
        color: inherit !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 15px;
        height: 100%;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background-color: $background;
    flex: 1;

    .top-bar {
      background-color: $light-background;
      height: 80px;
      padding: 20px 35px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .greetings {
        h3 {
          margin: 0;
          font-weight: 600;
          color: $font-title;
        }

        p {
          margin: 5px 0 0 0;
          font-size: 14px;
          font-weight: 500;
          color: $font-primary;
        }
      }

      .logged-user-info {
        margin-left: auto;
        width: 230px;
        position: relative;
        flex-shrink: 0;

        .logged-user-info__content {
          color: $font-primary;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &:hover {
            cursor: pointer;
          }

          img {
            width: 30px;
            height: 30px;
            border: 2px solid #dadada;
            padding: 1px;
            border-radius: 100%;
          }

          span {
            margin: 0 15px;
            font-weight: 500;
            $light-background-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $font-primary;
          }
        }

        .logged-user-info__options {
          position: absolute;
          width: 200px;
          background-color: $light-background;
          top: 65px;
          right: 0;
          box-shadow: 0px 0px 8px 0px rgba(216, 216, 216, 0.75);

          .option {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            padding: 10px 20px;
            color: $font-primary;

            &:hover {
              cursor: pointer;
              background-color: #f7f7f7;
            }

            span {
              margin-left: 10px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .content-children {
      overflow-y: auto;
      flex: 1;
      height: 100%;

      .section-title {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          margin: 0;
        }
      }

      table {
        border-collapse: collapse;

        thead {
          .tr:hover {
            background-color: $primary !important;
          }

          tr {
            th {
              font-size: 14px;
              line-height: 17px;
              color: #787878;
              font-weight: 500;
              text-align: left;
              padding: 15px 25px;
            }
          }
        }

        tbody {
          tr {
            background-color: $light-background;

            &:nth-child(even) {
              background-color: #fafafa;
            }

            td {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
              padding: 15px 25px;
            }

            .search-icon:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
