@import "designSystem/colors.scss";

.button-component {
	padding: 10px 25px;
	color: $font-light;
	border: none;
	text-decoration: none;
	border-radius: 10px;
	cursor: pointer;
	font-size: 15px;
	font-family: "GothamRounded";
	font-weight: 500;
	background: #000;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	.button-component-loading {
		flex: 1;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.button-content {
		display: flex;
		align-items: center;
		justify-content: center;

		& > svg {
			margin-right: 5px;
		}
	}

	&.secondary {
		background: $secondary;
		color: $font-light;
	}

	&.cancel {
		background-color: $font-disabled;
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		opacity: 0.9;
	}

	&.disabled {
		opacity: 0.5;
	}

	&.round {
		border-radius: 32px;
	}

	&.light {
		background: unset;
		color: $font-primary;
	}

	&.small {
		padding: 7px 15px;
	}

	&.no-actions {
		pointer-events: none;
	}

	&.danger {
		background: $error;
	}
}
